import React, { useState } from "react"
import { graphql } from "gatsby"
import {
  GatsbyImage as Img,
  getImage,
  ImageDataLike,
} from "gatsby-plugin-image"
import { compareDesc, format } from "date-fns"

import { Layout } from "../../components/Layout"
import { InnerPageNavigation } from "../../components/Navigation"
import { PageTileGroup } from "../../components/PageTile"

interface IEvent {
  node: {
    start_date: string
    end_date: string
    location: string
    description: string
    type: string
  }
}

interface EventsPageProps {
  data: {
    events: {
      edges: IEvent[]
    }
    events_colloquia_series_image: ImageDataLike
    events_gatherings_science_religion_image: ImageDataLike
  }
}

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const EventsPage: React.FC<EventsPageProps> = ({ data }) => {
  const {
    events,
    events_colloquia_series_image,
    // events_gatherings_science_religion_image,
  } = data

  const currentEvents = events.edges.filter(
    ({ node }) =>
      (new Date(node.start_date).getFullYear() === new Date().getFullYear() &&
        new Date(node.start_date).getMonth() >= new Date().getMonth()) ||
      new Date(node.start_date).getFullYear() > new Date().getFullYear()
  )

  const recentEvents = events.edges.filter(
    ({ node }) =>
      new Date(node.start_date).getFullYear() === new Date().getFullYear() &&
      new Date(node.start_date).getMonth() < new Date().getMonth()
  )

  const pastEvents = events.edges.filter(
    ({ node }) =>
      new Date(node.start_date).getFullYear() < new Date().getFullYear()
  )

  function _createDateSet(events: IEvent[], type: "month" | "year") {
    return [
      ...new Set(
        events.map(({ node }) => {
          const nodeDate: Date = new Date(node.start_date)
          if (type === "month") {
            return `${nodeDate.getMonth()}-${nodeDate.getFullYear()}`
          } else {
            return nodeDate.getFullYear().toString()
          }
        })
      ),
    ]
      .sort((a: string, b: string) => {
        return compareDesc(new Date(a), new Date(b))
      })
      .map((date: string) => {
        if (type === "month") {
          const [month, year] = date.split("-")
          return (
            <EventsPageTableByMonth
              key={`table-month-${month}-${year}`}
              events={events}
              month={month}
              year={year}
            />
          )
        } else {
          return (
            <EventsPageTableByYear
              key={`table-year-${date}`}
              events={events}
              year={date}
            />
          )
        }
      })
  }

  const eventsColloquiaSeriesImage = getImage(events_colloquia_series_image)

  return (
    <Layout title="Events">
      <InnerPageNavigation />
      <div className="container flex-grow px-4 mx-auto mt-10 mb-10 innerpage">
        <h1 className="flex flex-row content-center justify-around mb-6 font-serif text-3xl text-center sm:text-4xl md:text-5xl events-title text-ebony">
          Events
        </h1>

        <article className="flex p-8 mt-8 mb-16 bg-gray-200">
          {eventsColloquiaSeriesImage ? (
            <div className="flex-shrink-0 hidden w-24 ml-1 mr-3 md:ml-4 md:mr-8 md:w-32 md:-mt-16 md:-mb-12 sm:block">
              <Img image={eventsColloquiaSeriesImage} alt="" />
            </div>
          ) : null}

          <div>
            <h2 className="m-0 font-sans text-lg tracking-widest uppercase sm:text-xl md:text-2xl text-chambray">
              Colloquia series on human nature
            </h2>
            <p className="m-0 mb-10">
              The Institute for Studies in Global Prosperity is offering a
              series of online colloquia on the theme of human nature. The
              sessions of each colloquium involve presentations and discussions.
              Participants can log in{" "}
              <a href="/colloquia-series-on-human-nature/">here</a> to view the
              recordings of the live sessions. Additional information about the
              colloquium and a series of short pieces on the theme of naturalism
              and human nature, written by some of the presenters and
              discussants who participated in the colloquium, can be found{" "}
              <a href="/colloquia-series-on-human-nature/resources/">here</a>.
            </p>
          </div>
        </article>

        <h2 className="font-serif text:3xl md:text-4xl text-dark-burgundy">
          Upcoming Events
        </h2>
        {_createDateSet(currentEvents, "month")}

        <h2 className="font-serif text:3xl md:text-4xl text-dark-burgundy">
          Recent Events
        </h2>
        {_createDateSet(recentEvents, "month")}

        <h2 className="font-serif text:3xl md:text-4xl text-dark-burgundy">
          Past Events
        </h2>
        {_createDateSet(pastEvents, "year")}
      </div>
      <PageTileGroup />
    </Layout>
  )
}

interface EventsPageTableProps {
  events: IEvent[]
  month?: string
  year: string
}

export const EventsPageTableByYear: React.FC<EventsPageTableProps> = ({
  events,
  month,
  year,
}) => {
  const [expand, setExpand] = useState(false)

  return (
    <div
      className={expand ? "events-table-expanded" : "events-table-collapsed"}
    >
      <h3 className="flex justify-between w-1/3 mt-2 font-sans text-lg font-medium md:text-2xl xs:w-1/4 md:w-2/12">
        <span className="mr-2">{year}</span>{" "}
        <button
          type="button"
          className="mx-5"
          onClick={() => setExpand(!expand)}
        >
          {expand ? "-" : "+"}
        </button>
      </h3>
      <EventsPageTable events={events} month={month} year={year} />
    </div>
  )
}

export const EventsPageTableByMonth: React.FC<EventsPageTableProps> = ({
  events,
  month,
  year,
}) => {
  return (
    <>
      <h3 className="mt-6 font-sans text-lg font-medium md:text-2xl">
        {Months[Number(month)]} {year}
      </h3>
      <EventsPageTable events={events} month={month} year={year} />
    </>
  )
}

export const EventsPageTable: React.FC<EventsPageTableProps> = ({
  events,
  month,
  year,
}) => {
  function _dateOnly(date: Date): Date {
    return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000)
  }

  return (
    <table key={`${month}-${year}`} className="w-full">
      <tbody>
        {events
          .filter(({ node }) => {
            const nodeStartDate = new Date(node.start_date)
            const nodeStartDateOnly = _dateOnly(nodeStartDate)

            if (month && month.length) {
              return (
                Number(month) === nodeStartDateOnly.getMonth() &&
                Number(year) === nodeStartDateOnly.getFullYear()
              )
            } else {
              return Number(year) === nodeStartDateOnly.getFullYear()
            }
          })
          .map(({ node }) => {
            const nodeStartDate = new Date(node.start_date)
            const nodeStartDateOnly = _dateOnly(nodeStartDate)
            const nodeEndDate = new Date(node.end_date)
            const nodeEndDateOnly = _dateOnly(nodeEndDate)
            return (
              <EventsPageTableRow
                key={`${nodeStartDateOnly}-${nodeEndDateOnly}-${year}-${node.location}-${node.type}`}
                startDate={nodeStartDateOnly}
                endDate={nodeEndDateOnly}
                location={node.location}
                type={node.type}
                description={node.description}
              />
            )
          })}
      </tbody>
    </table>
  )
}

interface EventsPageTableRowProps {
  startDate: Date
  endDate: Date
  location: string
  type: string
  description: string
}

export const EventsPageTableRow: React.FC<EventsPageTableRowProps> = ({
  startDate,
  endDate,
  location,
  type,
  description,
}) => {
  const [showDescription, setShowDescription] = useState(false)
  return (
    <>
      <tr className="block even:bg-gray-200 md:table-row">
        <td className="inline w-1/4 font-light md:table-cell date-cell">
          {startDate === endDate
            ? format(startDate, "MMMM")
            : `${format(startDate, "dd MMM")} - ${format(endDate, "dd MMM")}`}
        </td>
        <td className="inline w-1/4 font-bold md:table-cell">{location}</td>
        <td className="flex justify-between w-full font-light">
          {type}{" "}
          {description && (
            <button
              type="button"
              className="self-center mx-5"
              onClick={() => setShowDescription(!showDescription)}
            >
              {showDescription ? "-" : "+"}
            </button>
          )}
        </td>
      </tr>
      {showDescription && (
        <tr className="p-5 bg-gray-300">
          <td className="hidden md:table-cell"></td>
          <td className="hidden md:table-cell"></td>
          <td className="px-2 py-2 md:py-4 md:px-0">{description}</td>
        </tr>
      )}
    </>
  )
}

export default EventsPage

export const eventsPageQuery = graphql`
  query {
    events: allEventsYaml(sort: { fields: start_date, order: DESC }) {
      edges {
        node {
          start_date
          end_date
          location
          description
          type
        }
      }
    }
    events_colloquia_series_image: file(
      relativePath: { eq: "events-page-colloquia-art.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          placeholder: BLURRED
          formats: [AVIF, AUTO, WEBP]
        )
      }
    }
  }
`
